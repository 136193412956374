import React from 'react'
import { Form } from 'react-final-form'
import { logIn } from 'services/auth'
import Input from './forms/input'
import Router from 'next/router'
import { GlobalContext } from 'components/global'
import Link from 'next/link'

const Login = ({ initialValues }) => {
  const { setGlobalState } = React.useContext(GlobalContext)
  const [error, setError] = React.useState('')

  const onSubmit = async values => {
    setError('')
    const res = await logIn(values)

    if (res.message) {
      return setError(res.message)
    }

    if (res.data && res.data._id) {
      setGlobalState({ currentUser: res.data })
      Router.push('/send')
    }
  }

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit}>
          <Input name="email" type="email" label="Email" required />
          <Input name="password" type="password" label="Password" required />

          <button type="submit" className="btn btn-blue btn-lg" disabled={submitting}>
            Log In
          </button>

          <Link href="/reset-password">
            <a className="link pull-right mt-2">Reset Password</a>
          </Link>
          <p className="error-text mt-2">{!!error && error}</p>
        </form>
      )}
    </Form>
  )
}

export default Login
