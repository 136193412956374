import LoginForm from 'components/login_form'
import ExternalLayout from 'components/layouts/external'
import { redirect } from 'services/util'
import { checkForAuthToken, getCurrentUser } from 'services/auth'
import Seo from 'components/seo'
import Link from 'next/link'

const LoginPage = ({ initEmail }) => {
  return (
    <>
      <Seo
        title="Handwrite | Sign In"
        description="Sign into your handwrite.io account"
        canonicalPath="login"
      />
      <div className="auth-page">
        <div className="well">
          <h1 className="mb-3 text-center color-blue">Login</h1>
          <LoginForm initialValues={{ email: initEmail }} />
        </div>
      </div>
    </>
  )
}

LoginPage.getInitialProps = async ({ req, res, query }) => {
  if (checkForAuthToken(req)) {
    var currentUser = await getCurrentUser()

    if (currentUser) {
      redirect('/send', res)
    }
  }

  return {
    currentUser,
    initEmail: query && query.email ? query.email : null,
  }
}

LoginPage.Layout = ExternalLayout

export default LoginPage
