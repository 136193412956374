import { useEffect, useState, cloneElement } from 'react'
import Link from 'next/link'
import { Container, Row, Col } from 'components/grid'
import classnames from 'classnames'
import { useRouter } from 'next/router'
import { setCookie, removeCookie } from 'services/cookies'

const findAndSetReferralSources = ({ utm_source, utm_medium, utm_campaign }) => {
  if (localStorage.getItem('refSource')) return

  let source

  if (utm_source || utm_medium || utm_campaign) {
    source = `UTM:${utm_source}|${utm_medium}|${utm_campaign}`
  } else if (document.referrer) {
    source = document.referrer
  }

  if (source) {
    localStorage.setItem('refSource', source)
  }
}

const ExternalLayout = ({ children }) => {
  const [modalShown, setModalShown] = useState(false)
  const [action, setAction] = useState('demo') // pricing, demo, sample are options
  const { pathname, query } = useRouter()

  useEffect(() => {
    const promo = Number(window.atob(query.p || ''))
    if (!!promo && typeof promo === 'number' && promo <= 50) {
      removeCookie('promo')
      setCookie('promo', promo)
    }

    findAndSetReferralSources(query)
  }, [query])

  useEffect(() => {
    window.Intercom('shutdown')
    window.Intercom('boot', {
      app_id: 'a6bl0tcv',
    })
  })

  const toggleModal = actionName => {
    setModalShown(!modalShown)
    setAction(actionName)
  }

  return (
    <>
      <div className={classnames('external-nav', { dark: pathname === '/' })}>
        <Container>
          <Row>
            <Col>
              <div className="pusher">
                <Link href="https://www.handwrite.io/">
                  <img
                    src="https://res.cloudinary.com/handwrite/image/upload/v1626650996/assets/FINAL_FILE_HANDWRITE_ON_TRANSPARENT_BG_j92mhw.png"
                    className="logo cw-100 hidden-sm"></img>
                </Link>

                <div className="text-right mt-1">
                  <Link href="/contact">
                    <a>Help</a>
                  </Link>
                  <Link href="/login">
                    <a>Login</a>
                  </Link>
                  <Link href="/signup">
                    <a>Start for Free</a>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="external">
        {cloneElement(children, {
          toggleModal,
        })}
      </div>
    </>
  )
}

export default ExternalLayout
