import PropTypes from 'prop-types'
import Head from 'next/head'

const Seo = ({ title, description, image, canonicalPath, noIndex = false }) => {
  const canonicalHref = canonicalPath
    ? `https://app.handwrite.io/${canonicalPath}`
    : 'https://app.handwrite.io'

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />

      <meta name="twitter:card" content="summary_large_image" />
      {/* <meta name="twitter:site" content="@ourtwitterhandle" /> */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />

      <link rel="canonical" href={canonicalHref} />

      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      {image && <meta property="og:image" content={image} />}
      <meta property="og:url" content={canonicalHref} />

      <meta property="og:description" content={description} />
      <meta property="og:site_name" content="Handwrite.io | Send handwritten notes online in seconds" />

      {noIndex && <meta name="robots" content="noindex" />}
    </Head>
  )
}

Seo.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  canonicalPath: PropTypes.string,
  image: PropTypes.string,
}

export default Seo
